import { Script } from 'gatsby';
import React from 'react';
import { AppContentPageTemplate } from './AppContentPageTemplate';
import { AppPageProps } from './AppPageProps';
import { DocsPageTemplate } from './DocsPageTemplate';
import { Head } from './Head';
import { PlainPageTemplate } from './PlainPageTemplate';

/**
 * Default template (aka Gatsby Layout) for our pages.
 * Its job is to load a more specific layout based on the path.
 *
 * Note this can be overridden if a page explicitly default exports a template.
 */
export default function DefaultPageTemplate(props: AppPageProps) {
  return (
    <>
      <Head pageProps={props} />
      <PageTemplateSwitch {...props} />
      <Script dangerouslySetInnerHTML={{ __html: renderAmplitudeSnippet() }} />
    </>
  );
}

function PageTemplateSwitch(props: AppPageProps) {
  const title = props.pageContext.frontmatter?.title;
  if (!title) {
    return <PlainPageTemplate {...props} />;
  } else if (props.location.pathname.startsWith('/learn')) {
    return <DocsPageTemplate {...props} />;
  } else if (props.location.pathname.startsWith('/app-content')) {
    return (
      <AppContentPageTemplate
        {...props}
        withoutApiToken={props.location.pathname.startsWith('/app-content/app-host-ready')}
      />
    );
  } else {
    return <PlainPageTemplate {...props} />;
  }
}

function renderAmplitudeSnippet() {
  return `!function(){"use strict";!function(e,t){var r=e.amplitude||{_q:[],_iq:{}};if(r.invoked)e.console&&console.error&&console.error("Amplitude snippet has been loaded.");else{var n=function(e,t){e.prototype[t]=function(){return this._q.push({name:t,args:Array.prototype.slice.call(arguments,0)}),this}},s=function(e,t,r){return function(n){e._q.push({name:t,args:Array.prototype.slice.call(r,0),resolve:n})}},o=function(e,t,r){e._q.push({name:t,args:Array.prototype.slice.call(r,0)})},i=function(e,t,r){e[t]=function(){if(r)return{promise:new Promise(s(e,t,Array.prototype.slice.call(arguments)))};o(e,t,Array.prototype.slice.call(arguments))}},a=function(e){for(var t=0;t<g.length;t++)i(e,g[t],!1);for(var r=0;r<m.length;r++)i(e,m[r],!0)};r.invoked=!0;var c=t.createElement("script");c.type="text/javascript",c.integrity="sha384-pY2pkwHaLM/6UIseFHVU3hOKr6oAvhLcdYkoRZyaMDWLjpM6B7nTxtOdE823WAOQ",c.crossOrigin="anonymous",c.async=!0,c.src="https://cdn.amplitude.com/libs/analytics-browser-2.11.0-min.js.gz",c.onload=function(){e.amplitude.runQueuedFunctions||console.log("[Amplitude] Error: could not load SDK")};var u=t.getElementsByTagName("script")[0];u.parentNode.insertBefore(c,u);for(var p=function(){return this._q=[],this},l=["add","append","clearAll","prepend","set","setOnce","unset","preInsert","postInsert","remove","getUserProperties"],d=0;d<l.length;d++)n(p,l[d]);r.Identify=p;for(var f=function(){return this._q=[],this},v=["getEventProperties","setProductId","setQuantity","setPrice","setRevenue","setRevenueType","setEventProperties"],y=0;y<v.length;y++)n(f,v[y]);r.Revenue=f;var g=["getDeviceId","setDeviceId","getSessionId","setSessionId","getUserId","setUserId","setOptOut","setTransport","reset","extendSession"],m=["init","add","remove","track","logEvent","identify","groupIdentify","setGroup","revenue","flush"];a(r),r.createInstance=function(e){return r._iq[e]={_q:[]},a(r._iq[e]),r._iq[e]},e.amplitude=r}}(window,document)}();
amplitude.init('1efde847a1dd16e6dbf8a242c1e2dd07', 'cookieless', {
  autocapture: true,
  defaultTracking: true,
  identityStorage: 'none',
});`;
}
