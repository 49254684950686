/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import DefaultPageTemplate from './src/templates/DefaultPageTemplate';
import * as React from 'react';
import posthog from 'posthog-js';

export const onClientEntry = function () {
  posthog.init(process.env.GATSBY_POSTHOG_KEY, {
    api_host: process.env.GATSBY_POSTHOG_HOST,
    autocapture: {
      url_allowlist: [/^https:\/\/(\w+\.?)plasmic\.app\/?/, /^http:\/\/localhost:\d+\/?/]
    },
    capture_pageview: false, // initial load captured by onRouteUpdate
    capture_pageleave: true, // final unload not captured by onRouteUpdate
    disable_session_recording: true, // don't record for session replay
    persistence: 'memory', // use cookieless tracking

    // Enable debug mode in development
    loaded: (posthog) => {
      if (process.env.NODE_ENV === 'development') {
        posthog.debug();
      }
    }
  });
};

export const onRouteUpdate = function ({ location, prevLocation }) {
  if (prevLocation) {
    posthog?.capture('$pageleave', {
      $current_url: prevLocation.href
    });
  }
  posthog?.capture('$pageview', {
    $current_url: location.href
  });
};

export const shouldUpdateScroll = function ({ routerProps }) {
  if ('scrollData' in (routerProps.location?.state || {})) {
    return false; // TabGroup will handle `scrollData`.
  } else {
    return true; // Use Gatsby default behavior.
  }
};

export const wrapPageElement = function ({ element, props }) {
  return <DefaultPageTemplate {...props}>{element}</DefaultPageTemplate>;
};
